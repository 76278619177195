// React

// Components
import { Card, CircularProgress, Grid, Icon, MenuItem, Select, Tab, Tabs } from "@mui/material";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Data
import { gql, useQuery } from "@apollo/client";
import SoftButton from "components/SoftButton";
import { MenuContext } from "context/menuContext";
import { useContext, useEffect, useState } from "react";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";
import Alumnos from "./components/Alumnos";
import DatosPersonales from "./components/DatosPersonales";
import Resumen from "./components/Resumen";

function AutogestionDocente() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedMateria, setSelectedMateria] = useState(null);
  const { menu } = useContext(MenuContext);
  const [expandCarreras, setExpandCarreras] = useState(false);
  const {
    data: usuario,
    loading,
    refetch,
  } = useQuery(gql`
    query getUser {
      currentUser {
        id
        tipoUser
        username
        nombre
        apellido
        email
        dni
        fechaNacimiento
        pais {
          id
        }
        genero
        localidad {
          id
          nombre
          provincia {
            id
            nombre
          }
        }
        materias {
          cargo
          materia {
            id
            codigo
            nombre
            nivel {
              codigo
              nombre
              planEstudio {
                codigo
                nombre
              }
            }
            comisiones(onlyDocente: true) {
              id
              nombre
              codigo
              cantidadInscriptos
              capacidad
              color
              nivel
              sede {
                id
              }
              docente {
                id
              }
              horarios {
                dia
                aula {
                  id
                  nombre
                }
                capacidad
                horarioInicio
                horarioFin
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (usuario?.currentUser?.materias.length > 0) {
      setSelectedMateria(usuario.currentUser.materias[
        usuario.currentUser.materias.length - 1
      ].materia);
    }
  }, [usuario]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              p={3}
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="space-between"
            >
              <SoftBox
                mr={2}
                sx={{
                  display: {
                    xs: "none",
                    sm: "block",
                  },
                  width: "100%",
                }}
              >
                <Tabs
                  value={selectedTab}
                  onChange={(e, value) => {
                    setSelectedTab(value);
                  }}
                >
                  <Tab
                    label={
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox display="flex" alignItems="center">
                          <Icon color={selectedTab === 0 ? "primary" : "secondary"}>
                            description
                          </Icon>
                        </SoftBox>
                        <SoftTypography
                          variant="h6"
                          fontWeight={selectedTab === 0 ? "bold" : "regular"}
                        >
                          Resumen
                        </SoftTypography>
                      </SoftBox>
                    }
                    value={0}
                  />
                  <Tab
                    label={
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox display="flex" alignItems="center">
                          <Icon color={selectedTab === 1 ? "primary" : "secondary"}>school</Icon>
                        </SoftBox>
                        <SoftTypography
                          variant="h6"
                          fontWeight={selectedTab === 1 ? "bold" : "regular"}
                        >
                          Alumnos
                        </SoftTypography>
                      </SoftBox>
                    }
                    value={1}
                  />
                  <Tab
                    label={
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox display="flex" alignItems="center">
                          <Icon color={selectedTab === 2 ? "primary" : "secondary"}>
                            account_circle
                          </Icon>
                        </SoftBox>
                        <SoftTypography
                          variant="h6"
                          fontWeight={selectedTab === 2 ? "bold" : "regular"}
                        >
                          Datos personales
                        </SoftTypography>
                      </SoftBox>
                    }
                    value={2}
                  />
                </Tabs>
              </SoftBox>
              <SoftBox
                sx={{
                  display: {
                    xs: "block",
                    sm: "none",
                  },
                  width: "100%",
                }}
                mb={2}
              >
                <Select
                  value={selectedTab}
                  onChange={(e) => {
                    setSelectedTab(e.target.value);
                  }}
                >
                  <MenuItem value={0}>
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox display="flex" alignItems="center">
                        <Icon color={selectedTab === 0 ? "primary" : "secondary"}>description</Icon>
                        &nbsp; Resumen
                      </SoftBox>
                    </SoftBox>
                  </MenuItem>
                  <MenuItem value={1}>
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox display="flex" alignItems="center">
                        <Icon color={selectedTab === 1 ? "primary" : "secondary"}>school</Icon>
                        &nbsp; Inscripciones
                      </SoftBox>
                    </SoftBox>
                  </MenuItem>
                  <MenuItem value={2}>
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox display="flex" alignItems="center">
                        <Icon color={selectedTab === 2 ? "primary" : "secondary"}>
                          account_circle
                        </Icon>
                        &nbsp; Datos personales
                      </SoftBox>
                    </SoftBox>
                  </MenuItem>
                </Select>
              </SoftBox>
              <SoftBox>
                <SoftButton
                  color="uproYellow"
                  circular
                  sx={{
                    textWrap: "nowrap",
                  }}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={loading || !usuario?.currentUser?.materias.length}
                  onClick={(event) => {
                    setExpandCarreras(true);
                    menu({
                      open: event.currentTarget,
                      align: "right",
                      onClose: () => {
                        setExpandCarreras(false);
                      },
                      options: usuario?.currentUser?.materias.map((materia) => ({
                        name: `${materia.materia.nivel.planEstudio.codigo} - ${materia.materia.nivel.codigo} - ${materia.materia.nombre}`,
                        icon: {
                          icon: "school",
                          color:
                            selectedMateria?.materia?.id === materia.materia.id
                              ? getUproColor("uproYellow")
                              : getUproColor("uproGreen"),
                        },
                        onClick: () => {
                          setSelectedMateria(materia.materia);
                        },
                      })),
                    });
                  }}
                >
                  {loading ? (
                    <CircularProgress size={15} color="inherit" />
                  ) : (
                    <>
                      <Icon>school</Icon>
                      &nbsp;{selectedMateria ? `${selectedMateria.nivel.planEstudio.codigo} - ${selectedMateria.nivel.codigo} - ${selectedMateria.nombre}` : "Seleccione una materia"}&nbsp;
                      <Icon
                        sx={{
                          rotate: expandCarreras ? "180deg" : "0deg",
                          transition: "all 0.5s",
                        }}
                      >
                        expand_more
                      </Icon>
                    </>
                  )}
                </SoftButton>
              </SoftBox>
            </SoftBox>
            <Grid container spacing={2} p={3} pt={0}>
              <Grid item xs={12}>
                {selectedTab === 0 && (
                  <Resumen
                    usuario={usuario?.currentUser}
                    materia={selectedMateria}
                    loading={loading}
                    refetch={refetch}
                    setTab={setSelectedTab}
                  />
                )}
                {selectedTab === 1 && (
                  <Alumnos
                    usuario={usuario?.currentUser}
                    materia={selectedMateria}
                    loading={loading}
                  />
                )}
                {selectedTab === 2 && (
                  <DatosPersonales
                    selectedUsuario={usuario?.currentUser}
                    loading={loading}
                    refetch={refetch}
                  />
                )}
              </Grid>
            </Grid>
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AutogestionDocente;
