import { gql, useQuery } from "@apollo/client";
import { Card, Collapse, Fade, Grid, Icon, Modal, Slide, Tooltip } from "@mui/material";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { useApolloClient } from "context/apolloClientContext";
import { MenuContext } from "context/menuContext";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { getUproColor } from "utils/colors";
import { formatMoneyPunto } from "utils/formatters";
import ModalConfirmarPedido from "../ModalConfirmarPedido";

const foodIcons = {
  Entrada: "restaurant",
  "Desayuno / Merienda": "emoji_food_beverage",
  "Plato principal": "lunch_dining",
  Postre: "icecream",
  Ensaladas: "rice_bowl",
  "Bebidas sin alcohol": "water_drop_outlined",
  "Bebidas con alcohol": "local_bar",
};

const getFoodIcon = (categoria) => {
  return foodIcons[categoria] || "restaurant";
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "90%", xxl: "70%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalNuevoPedido({ open, handleClose, refetch }) {
  const [fechaPedido, setFechaPedido] = useState(dayjs().add(1, "day"));
  const [openModalConfirmar, setOpenModalConfirmar] = useState(false);
  const [carrito, setCarrito] = useState([]);
  const client = useApolloClient("clientComedor");
  const [expandedCategorias, setExpandedCategorias] = useState([]);
  const [expandFechas, setExpandFechas] = useState(false);
  const { menu } = useContext(MenuContext);

  const {
    loading,
    error,
    data,
    refetch: refetchProductos,
  } = useQuery(
    gql`
      query getProductos($order: Order, $filter: JSON) {
        productos(order: $order, filter: $filter) {
          id
          nombre
          disponibilidad
          precioCarta
          descripcion
          combo
          tipoProducto {
            id
            tipo
          }
          categoriaProducto {
            id
            categoria
          }
          ingredientes {
            id
          }
          productosCombo {
            id
            nombre
          }
        }
      }
    `,
    {
      client,
      variables: {
        order: { field: "nombre", order: "ASC" },
        filter: {
          disponibilidad: true,
        },
      },
      fetchPolicy: "network-only",
    }
  );
  const { data: dataCategorias } = useQuery(
    gql`
      query categoriasProducto {
        categoriasProducto {
          id
          categoria
        }
      }
    `,
    {
      client,
      fetchPolicy: "network-only",
    }
  );

  const { data: menusDelDia } = useQuery(
    gql`
      query getMenusDelDia {
        menusDelDia {
          id
          precio
          fechaMenu
          producto {
            id
          }
        }
      }
    `,
    {
      client,
    }
  );

  useEffect(() => {
    if (!open) {
      refetchProductos();
      setCarrito([]);
      setFechaPedido(dayjs().add(1, "day"));
      setExpandedCategorias([]);
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Nuevo pedido</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox pt={3}>
              <SoftBox
                display="flex"
                flexDirection={{
                  xs: "column",
                  sm: "row",
                }}
                justifyContent="space-between"
                gap={2}
              >
                <SoftBox
                  sx={{
                    display: {
                      xs: "block",
                      sm: "none",
                    },
                  }}
                >
                  <Card
                    sx={{
                      width: "100%",
                    }}
                  >
                    <SoftBox p={2}>
                      <SoftBox>
                        <SoftTypography variant="h6">Fecha del pedido</SoftTypography>
                      </SoftBox>
                      <SoftBox mt={2}>
                        <SoftButton
                          color="uproYellow"
                          fullWidth
                          circular
                          onClick={(event) => {
                            setExpandFechas(true);
                            menu({
                              open: event.currentTarget,
                              align: "right",
                              options: [
                                {
                                  name: dayjs().add(1, "day").format("DD/MM/YYYY"),
                                  icon: {
                                    icon:
                                      dayjs(fechaPedido).format("DD/MM/YYYY") ===
                                      dayjs().add(1, "day").format("DD/MM/YYYY")
                                        ? "event_available"
                                        : "today",
                                    color:
                                      dayjs(fechaPedido).format("DD/MM/YYYY") ===
                                      dayjs().add(1, "day").format("DD/MM/YYYY")
                                        ? "primary"
                                        : getUproColor("uproYellow"),
                                  },
                                  onClick: () => {
                                    setFechaPedido(dayjs().add(1, "day"));
                                    setExpandFechas(false);
                                  },
                                },
                                {
                                  name: dayjs().add(2, "day").format("DD/MM/YYYY"),
                                  icon: {
                                    icon:
                                      dayjs(fechaPedido).format("DD/MM/YYYY") ===
                                      dayjs().add(2, "day").format("DD/MM/YYYY")
                                        ? "event_available"
                                        : "today",
                                    color:
                                      dayjs(fechaPedido).format("DD/MM/YYYY") ===
                                      dayjs().add(2, "day").format("DD/MM/YYYY")
                                        ? "primary"
                                        : getUproColor("uproYellow"),
                                  },
                                  onClick: () => {
                                    setFechaPedido(dayjs().add(2, "day"));
                                    setExpandFechas(false);
                                  },
                                },
                                {
                                  name: dayjs().add(3, "day").format("DD/MM/YYYY"),
                                  icon: {
                                    icon:
                                      dayjs(fechaPedido).format("DD/MM/YYYY") ===
                                      dayjs().add(3, "day").format("DD/MM/YYYY")
                                        ? "event_available"
                                        : "today",
                                    color:
                                      dayjs(fechaPedido).format("DD/MM/YYYY") ===
                                      dayjs().add(3, "day").format("DD/MM/YYYY")
                                        ? "primary"
                                        : getUproColor("uproYellow"),
                                  },
                                  onClick: () => {
                                    setFechaPedido(dayjs().add(3, "day"));
                                    setExpandFechas(false);
                                  },
                                },
                                {
                                  name: dayjs().add(4, "day").format("DD/MM/YYYY"),
                                  icon: {
                                    icon:
                                      dayjs(fechaPedido).format("DD/MM/YYYY") ===
                                      dayjs().add(4, "day").format("DD/MM/YYYY")
                                        ? "event_available"
                                        : "today",
                                    color:
                                      dayjs(fechaPedido).format("DD/MM/YYYY") ===
                                      dayjs().add(4, "day").format("DD/MM/YYYY")
                                        ? "primary"
                                        : getUproColor("uproYellow"),
                                  },
                                  onClick: () => {
                                    setFechaPedido(dayjs().add(4, "day"));
                                    setExpandFechas(false);
                                  },
                                },
                                {
                                  name: dayjs().add(5, "day").format("DD/MM/YYYY"),
                                  icon: {
                                    icon:
                                      dayjs(fechaPedido).format("DD/MM/YYYY") ===
                                      dayjs().add(5, "day").format("DD/MM/YYYY")
                                        ? "event_available"
                                        : "today",
                                    color:
                                      dayjs(fechaPedido).format("DD/MM/YYYY") ===
                                      dayjs().add(5, "day").format("DD/MM/YYYY")
                                        ? "primary"
                                        : getUproColor("uproYellow"),
                                  },
                                  onClick: () => {
                                    setFechaPedido(dayjs().add(5, "day"));
                                    setExpandFechas(false);
                                  },
                                },
                              ],
                              handleClose: () => {},
                              onClose: () => {
                                setExpandFechas(false);
                              },
                            });
                          }}
                        >
                          <Icon>calendar_month</Icon>
                          &nbsp;
                          {dayjs(fechaPedido).format("DD/MM/YYYY")}
                          &nbsp;
                          <Icon
                            sx={{
                              rotate: expandFechas ? "180deg" : "0deg",
                              transition: "all 0.5s",
                            }}
                          >
                            expand_more
                          </Icon>
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </SoftBox>
                <SoftBox sx={{ width: "100%" }}>
                  <Card
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      py={1}
                      sx={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon color="primary">store</Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          Listado de productos
                        </SoftTypography>
                      </SoftBox>
                      <Tooltip title="Fecha del pedido (click para cambiar)" placement="top">
                        <SoftBox
                          display={{
                            xs: "none",
                            sm: "flex",
                          }}
                          alignItems="center"
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={(event) => {
                            setExpandFechas(true);
                            menu({
                              open: event.currentTarget,
                              align: "right",
                              options: [
                                {
                                  name: dayjs().add(1, "day").format("DD/MM/YYYY"),
                                  icon: {
                                    icon:
                                      dayjs(fechaPedido).format("DD/MM/YYYY") ===
                                      dayjs().add(1, "day").format("DD/MM/YYYY")
                                        ? "event_available"
                                        : "today",
                                    color:
                                      dayjs(fechaPedido).format("DD/MM/YYYY") ===
                                      dayjs().add(1, "day").format("DD/MM/YYYY")
                                        ? "primary"
                                        : getUproColor("uproYellow"),
                                  },
                                  onClick: () => {
                                    setFechaPedido(dayjs().add(1, "day"));
                                    setExpandFechas(false);
                                  },
                                },
                                {
                                  name: dayjs().add(2, "day").format("DD/MM/YYYY"),
                                  icon: {
                                    icon:
                                      dayjs(fechaPedido).format("DD/MM/YYYY") ===
                                      dayjs().add(2, "day").format("DD/MM/YYYY")
                                        ? "event_available"
                                        : "today",
                                    color:
                                      dayjs(fechaPedido).format("DD/MM/YYYY") ===
                                      dayjs().add(2, "day").format("DD/MM/YYYY")
                                        ? "primary"
                                        : getUproColor("uproYellow"),
                                  },
                                  onClick: () => {
                                    setFechaPedido(dayjs().add(2, "day"));
                                    setExpandFechas(false);
                                  },
                                },
                                {
                                  name: dayjs().add(3, "day").format("DD/MM/YYYY"),
                                  icon: {
                                    icon:
                                      dayjs(fechaPedido).format("DD/MM/YYYY") ===
                                      dayjs().add(3, "day").format("DD/MM/YYYY")
                                        ? "event_available"
                                        : "today",
                                    color:
                                      dayjs(fechaPedido).format("DD/MM/YYYY") ===
                                      dayjs().add(3, "day").format("DD/MM/YYYY")
                                        ? "primary"
                                        : getUproColor("uproYellow"),
                                  },
                                  onClick: () => {
                                    setFechaPedido(dayjs().add(3, "day"));
                                    setExpandFechas(false);
                                  },
                                },
                                {
                                  name: dayjs().add(4, "day").format("DD/MM/YYYY"),
                                  icon: {
                                    icon:
                                      dayjs(fechaPedido).format("DD/MM/YYYY") ===
                                      dayjs().add(4, "day").format("DD/MM/YYYY")
                                        ? "event_available"
                                        : "today",
                                    color:
                                      dayjs(fechaPedido).format("DD/MM/YYYY") ===
                                      dayjs().add(4, "day").format("DD/MM/YYYY")
                                        ? "primary"
                                        : getUproColor("uproYellow"),
                                  },
                                  onClick: () => {
                                    setFechaPedido(dayjs().add(4, "day"));
                                    setExpandFechas(false);
                                  },
                                },
                                {
                                  name: dayjs().add(5, "day").format("DD/MM/YYYY"),
                                  icon: {
                                    icon:
                                      dayjs(fechaPedido).format("DD/MM/YYYY") ===
                                      dayjs().add(5, "day").format("DD/MM/YYYY")
                                        ? "event_available"
                                        : "today",
                                    color:
                                      dayjs(fechaPedido).format("DD/MM/YYYY") ===
                                      dayjs().add(5, "day").format("DD/MM/YYYY")
                                        ? "primary"
                                        : getUproColor("uproYellow"),
                                  },
                                  onClick: () => {
                                    setFechaPedido(dayjs().add(5, "day"));
                                    setExpandFechas(false);
                                  },
                                },
                              ],
                              handleClose: () => {},
                              onClose: () => {
                                setExpandFechas(false);
                              },
                            });
                          }}
                        >
                          <SoftBox mr={1} display="flex" alignItems="center">
                            <Icon color="primary">calendar_month</Icon>
                          </SoftBox>
                          <SoftTypography variant="h6" fontWeight="bold">
                            {dayjs(fechaPedido).format("DD/MM/YYYY")}
                          </SoftTypography>
                          <SoftBox ml={1} display="flex" alignItems="center">
                            <Icon
                              sx={{
                                color: getUproColor("uproGreen"),
                                rotate: expandFechas ? "180deg" : "0deg",
                                transition: "all 0.5s",
                              }}
                            >
                              expand_more
                            </Icon>
                          </SoftBox>
                        </SoftBox>
                      </Tooltip>
                    </SoftBox>
                    <Collapse
                      in={dataCategorias}
                      sx={{
                        maxHeight: {
                          xs: "100%",
                          sm: "70vh",
                        },
                        overflowY: "auto",
                      }}
                    >
                      {loading ? (
                        <Grid container spacing={2} p={2}>
                          <Loading />
                        </Grid>
                      ) : dataCategorias?.categoriasProducto?.length > 0 ? (
                        dataCategorias?.categoriasProducto?.map((categoria, index) => (
                          <SoftBox key={index}>
                            <SoftBox
                              px={2}
                              py={1}
                              sx={{
                                borderBottom:
                                  index === dataCategorias?.categoriasProducto?.length - 1
                                    ? "none"
                                    : "1px solid rgba(0, 0, 0, 0.12)",
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                                },
                              }}
                              onClick={() => {
                                data?.productos?.filter(
                                  (producto) => producto.categoriaProducto.id === categoria.id
                                ).length > 0 &&
                                  setExpandedCategorias(
                                    expandedCategorias.includes(categoria.id)
                                      ? expandedCategorias.filter((item) => item !== categoria.id)
                                      : [...expandedCategorias, categoria.id]
                                  );
                              }}
                            >
                              <SoftBox display="flex" justifyContent="space-between">
                                <SoftBox display="flex" alignItems="center">
                                  <SoftBox display="flex" alignItems="center">
                                    <SoftBox
                                      mr={{
                                        xs: 0,
                                        sm: 1,
                                      }}
                                      sx={{
                                        display: {
                                          xs: "none",
                                          sm: "block",
                                        },
                                      }}
                                    >
                                      <Tooltip title={categoria.categoria} placement="top">
                                        <Icon
                                          sx={{
                                            color: getUproColor("uproGreen"),
                                            cursor: "pointer",
                                          }}
                                        >
                                          {getFoodIcon(categoria.categoria)}
                                        </Icon>
                                      </Tooltip>
                                    </SoftBox>
                                    <SoftBox display="flex" flexDirection="column">
                                      <SoftBox display="flex" alignItems="center" mr={2}>
                                        <SoftTypography variant="h6">
                                          {categoria.categoria}
                                        </SoftTypography>
                                        <SoftBox
                                          ml={1}
                                          display={{
                                            xs: "none",
                                            sm: "flex",
                                          }}
                                          alignItems="center"
                                        >
                                          <SoftBadge
                                            color={
                                              data?.productos?.filter(
                                                (producto) =>
                                                  producto.categoriaProducto.id === categoria.id
                                              ).length > 0
                                                ? "primary"
                                                : "secondary"
                                            }
                                            badgeContent={data?.productos
                                              ?.filter(
                                                (producto) =>
                                                  producto.categoriaProducto.id === categoria.id
                                              )
                                              .length.toString()}
                                          />
                                        </SoftBox>
                                        {data?.productos?.filter(
                                          (producto) =>
                                            producto.categoriaProducto.id === categoria.id &&
                                            menusDelDia?.menusDelDia
                                              ?.map((menu) => menu.producto.id)
                                              .includes(producto.id)
                                        ).length > 0 && (
                                          <SoftBox
                                            ml={1}
                                            display={{
                                              xs: "none",
                                              sm: "flex",
                                            }}
                                            alignItems="center"
                                          >
                                            <SoftBadge color="uproGreen" badgeContent="Ofertas" />
                                          </SoftBox>
                                        )}
                                      </SoftBox>
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                                <SoftBox
                                  display="flex"
                                  justifyContent="flex-end"
                                  my={{
                                    xs: 1,
                                    sm: 0,
                                  }}
                                  alignItems="center"
                                >
                                  <SoftBox ml={2}>
                                    <Tooltip title="Expandir" placement="top">
                                      <SoftButton
                                        color="uproYellow"
                                        circular
                                        iconOnly
                                        fullWidth={{
                                          xs: true,
                                          sm: false,
                                        }}
                                        disabled={
                                          data?.productos?.filter(
                                            (producto) =>
                                              producto.categoriaProducto.id === categoria.id
                                          ).length === 0
                                        }
                                        onClick={() => {
                                          setExpandedCategorias(
                                            expandedCategorias.includes(categoria.id)
                                              ? expandedCategorias.filter(
                                                  (item) => item !== categoria.id
                                                )
                                              : [...expandedCategorias, categoria.id]
                                          );
                                        }}
                                      >
                                        <Icon
                                          sx={{
                                            rotate: expandedCategorias.includes(categoria.id)
                                              ? "180deg"
                                              : "0deg",
                                            transition: "all 0.5s",
                                          }}
                                        >
                                          expand_more
                                        </Icon>
                                      </SoftButton>
                                    </Tooltip>
                                  </SoftBox>
                                </SoftBox>
                              </SoftBox>
                            </SoftBox>
                            <Collapse
                              in={
                                expandedCategorias.includes(categoria.id) &&
                                data?.productos?.filter(
                                  (producto) => producto.categoriaProducto.id === categoria.id
                                ).length > 0
                              }
                            >
                              {data?.productos
                                ?.filter(
                                  (producto) => producto.categoriaProducto.id === categoria.id
                                )
                                .map((producto, index) => (
                                  <SoftBox key={index}>
                                    <SoftBox
                                      px={2}
                                      py={1}
                                      sx={{
                                        borderBottom:
                                          index === data?.productos?.length - 1
                                            ? "none"
                                            : "1px solid rgba(0, 0, 0, 0.12)",
                                        "&:hover": {
                                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                                        },
                                      }}
                                    >
                                      <SoftBox
                                        display={{
                                          xs: "flex-row",
                                          sm: "flex",
                                        }}
                                        justifyContent="space-between"
                                        pl={{
                                          xs: 0,
                                          sm: 2,
                                        }}
                                      >
                                        <SoftBox display="flex" alignItems="center">
                                          <SoftBox display="flex" alignItems="center">
                                            <SoftBox
                                              mr={{
                                                xs: 0,
                                                sm: 1,
                                              }}
                                              sx={{
                                                display: {
                                                  xs: "none",
                                                  sm: "block",
                                                },
                                              }}
                                            >
                                              <Icon
                                                sx={{
                                                  color: getUproColor("uproPink"),
                                                }}
                                              >
                                                subdirectory_arrow_right
                                              </Icon>
                                            </SoftBox>
                                            <SoftBox
                                              mr={{
                                                xs: 0,
                                                sm: 1,
                                              }}
                                              sx={{
                                                display: {
                                                  xs: "none",
                                                  sm: "block",
                                                },
                                              }}
                                            >
                                              <Tooltip
                                                title={producto?.categoriaProducto?.categoria}
                                                placement="top"
                                              >
                                                <Icon
                                                  sx={{
                                                    color: getUproColor("uproYellow"),
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {getFoodIcon(
                                                    producto?.categoriaProducto?.categoria
                                                  )}
                                                </Icon>
                                              </Tooltip>
                                            </SoftBox>
                                            <SoftBox display="flex" flexDirection="column">
                                              <SoftBox
                                                display="flex"
                                                flexDirection={{
                                                  xs: "column-reverse",
                                                  sm: "row",
                                                }}
                                                alignItems={{
                                                  xs: "flex-start",
                                                  sm: "center",
                                                }}
                                                mr={{
                                                  xs: 0,
                                                  sm: 2,
                                                }}
                                              >
                                                <SoftTypography variant="h6">
                                                  {producto.nombre}
                                                </SoftTypography>
                                                {menusDelDia?.menusDelDia
                                                  ?.map((menu) => menu.producto.id)
                                                  .includes(producto.id) && (
                                                  <SoftBox
                                                    ml={{
                                                      xs: 0,
                                                      sm: 1,
                                                    }}
                                                    my={{
                                                      xs: 1,
                                                      sm: 0,
                                                    }}
                                                    display="flex"
                                                    alignItems="center"
                                                  >
                                                    <SoftBadge
                                                      color="uproGreen"
                                                      badgeContent="Oferta del día"
                                                    />
                                                  </SoftBox>
                                                )}
                                              </SoftBox>
                                              <Tooltip
                                                title={
                                                  producto.descripcion.length > 0
                                                    ? producto.descripcion
                                                    : producto.combo
                                                    ? producto.productosCombo
                                                        .map(
                                                          (productoCombo) => productoCombo.nombre
                                                        )
                                                        .join(" + ")
                                                    : producto?.tipoProducto?.tipo.length > 0
                                                    ? producto?.tipoProducto?.tipo
                                                    : "Sin descripción"
                                                }
                                                placement="right"
                                              >
                                                <SoftTypography variant="caption" color="secondary">
                                                  {producto.descripcion.length > 50
                                                    ? producto.descripcion.substring(0, 50) + "..."
                                                    : producto.descripcion.length > 0
                                                    ? producto.descripcion
                                                    : producto.combo
                                                    ? producto.productosCombo
                                                        .map(
                                                          (productoCombo) => productoCombo.nombre
                                                        )
                                                        .join(" + ").length > 50
                                                      ? producto.productosCombo
                                                          .map(
                                                            (productoCombo) => productoCombo.nombre
                                                          )
                                                          .join(" + ")
                                                          .substring(0, 50) + "..."
                                                      : producto.productosCombo
                                                          .map(
                                                            (productoCombo) => productoCombo.nombre
                                                          )
                                                          .join(" + ")
                                                    : producto?.tipoProducto?.tipo.length > 0
                                                    ? producto?.tipoProducto?.tipo
                                                    : "Sin descripción"}
                                                </SoftTypography>
                                              </Tooltip>
                                            </SoftBox>
                                          </SoftBox>
                                        </SoftBox>
                                        <SoftBox
                                          display="flex"
                                          justifyContent="flex-end"
                                          mb={{
                                            xs: 1,
                                            sm: 0,
                                          }}
                                          mt={{
                                            xs: 2,
                                            sm: 0,
                                          }}
                                          alignItems="center"
                                        >
                                          <SoftBox
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="flex-end"
                                          >
                                            {menusDelDia?.menusDelDia
                                              ?.map((menu) => menu.producto.id)
                                              .includes(producto.id) && (
                                              <SoftTypography
                                                variant="caption"
                                                color="secondary"
                                                sx={{
                                                  textDecoration: "line-through",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                $ {formatMoneyPunto(producto.precioCarta)}
                                              </SoftTypography>
                                            )}
                                            <SoftTypography
                                              variant="h6"
                                              color={
                                                menusDelDia?.menusDelDia
                                                  ?.map((menu) => menu.producto.id)
                                                  .includes(producto.id)
                                                  ? "primary"
                                                  : "text"
                                              }
                                              sx={{
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              ${" "}
                                              {formatMoneyPunto(
                                                menusDelDia?.menusDelDia?.find(
                                                  (menu) => menu.producto.id === producto.id
                                                )?.precio || producto.precioCarta
                                              )}
                                            </SoftTypography>
                                          </SoftBox>
                                          <SoftBox
                                            sx={{
                                              display: {
                                                xs: "none",
                                                sm: "block",
                                              },
                                            }}
                                            ml={2}
                                          >
                                            <Tooltip title={"Agregar al carrito"} placement="top">
                                              <SoftButton
                                                color="uproGreen"
                                                circular
                                                iconOnly
                                                fullWidth={{
                                                  xs: true,
                                                  sm: false,
                                                }}
                                                onClick={() => {
                                                  setCarrito([
                                                    ...carrito,
                                                    {
                                                      ...producto,
                                                      uniqueId: new Date().getTime(),
                                                    },
                                                  ]);
                                                }}
                                              >
                                                <Icon>add_shopping_cart</Icon>
                                              </SoftButton>
                                            </Tooltip>
                                          </SoftBox>
                                          <SoftBox
                                            ml={2}
                                            sx={{
                                              display: {
                                                xs: "block",
                                                sm: "none",
                                              },
                                            }}
                                          >
                                            <SoftButton
                                              color="uproGreen"
                                              circular
                                              fullWidth
                                              onClick={() => {
                                                setCarrito([
                                                  ...carrito,
                                                  {
                                                    ...producto,
                                                    uniqueId: new Date().getTime(),
                                                  },
                                                ]);
                                              }}
                                            >
                                              <Icon>add_shopping_cart</Icon>
                                              &nbsp;Agregar
                                            </SoftButton>
                                          </SoftBox>
                                        </SoftBox>
                                      </SoftBox>
                                    </SoftBox>
                                  </SoftBox>
                                ))}
                            </Collapse>
                          </SoftBox>
                        ))
                      ) : (
                        <Grid container spacing={2} p={2}>
                          <SinDatos />
                        </Grid>
                      )}
                    </Collapse>
                  </Card>
                </SoftBox>
                <Slide
                  in={carrito.filter((item) => !item.eliminado).length > 0}
                  direction="left"
                  mountOnEnter
                  unmountOnExit
                >
                  <SoftBox
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "40%",
                      },
                    }}
                  >
                    <Card
                      sx={{
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        zIndex: 2,
                      }}
                    >
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        px={2}
                        py={1}
                        sx={{
                          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                      >
                        <SoftBox display="flex" alignItems="center">
                          <SoftBox mr={1} display="flex" alignItems="center">
                            <Icon color="primary">shopping_cart</Icon>
                          </SoftBox>
                          <SoftTypography variant="h6" fontWeight="bold">
                            Carrito
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                      <Collapse
                        in={data}
                        sx={{
                          maxHeight: {
                            xs: "100%",
                            sm: "50vh",
                          },
                          overflowY: "auto",
                        }}
                      >
                        {carrito.map((producto, index) => (
                          <Slide
                            in={!producto.eliminado}
                            key={index}
                            direction="right"
                            mountOnEnter
                            unmountOnExit
                          >
                            <SoftBox>
                              <SoftBox
                                px={2}
                                py={1}
                                sx={{
                                  borderBottom:
                                    index === carrito.length - 1 ||
                                    producto?.uniqueId ===
                                      carrito.filter((item) => !item.eliminado)?.slice(-1)[0]
                                        ?.uniqueId
                                      ? "none"
                                      : "1px solid rgba(0, 0, 0, 0.12)",
                                  "&:hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                  },
                                }}
                              >
                                <SoftBox
                                  display={{
                                    xs: "flex-row",
                                    sm: "flex",
                                  }}
                                  justifyContent="space-between"
                                >
                                  <SoftBox
                                    display="flex"
                                    alignItems="center"
                                    justifyContent={{
                                      xs: "space-between",
                                      sm: "flex-start",
                                    }}
                                  >
                                    <SoftBox display="flex" alignItems="center">
                                      <SoftBox
                                        mr={{
                                          xs: 0,
                                          sm: 1,
                                        }}
                                        sx={{
                                          display: {
                                            xs: "none",
                                            sm: "block",
                                          },
                                        }}
                                      >
                                        <Tooltip
                                          title={producto?.categoriaProducto?.categoria}
                                          placement="top"
                                        >
                                          <Icon
                                            sx={{
                                              color: getUproColor("uproYellow"),
                                              cursor: "pointer",
                                            }}
                                          >
                                            {getFoodIcon(producto?.categoriaProducto?.categoria)}
                                          </Icon>
                                        </Tooltip>
                                      </SoftBox>
                                      <SoftBox display="flex" flexDirection="column">
                                        <SoftBox display="flex" alignItems="center" mr={2}>
                                          <SoftTypography variant="h6">
                                            {producto.nombre}
                                            {menusDelDia?.menusDelDia
                                              ?.map((menu) => menu.producto.id)
                                              .includes(producto.id) && (
                                              <Tooltip title="Oferta del día" placement="top">
                                                <Icon
                                                  sx={{
                                                    marginLeft: 0.5,
                                                    color: getUproColor("uproGreen"),
                                                    verticalAlign: "middle",
                                                  }}
                                                >
                                                  discount
                                                </Icon>
                                              </Tooltip>
                                            )}
                                          </SoftTypography>
                                        </SoftBox>
                                      </SoftBox>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftBox
                                    display="flex"
                                    justifyContent="flex-end"
                                    mb={{
                                      xs: 1,
                                      sm: 0,
                                    }}
                                    mt={{
                                      xs: 2,
                                      sm: 0,
                                    }}
                                    alignItems="center"
                                  >
                                    <SoftBox
                                      display="flex"
                                      flexDirection="column"
                                      alignItems="flex-end"
                                    >
                                      {menusDelDia?.menusDelDia
                                        ?.map((menu) => menu.producto.id)
                                        .includes(producto.id) && (
                                        <SoftTypography
                                          variant="caption"
                                          color="secondary"
                                          sx={{
                                            textDecoration: "line-through",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          $ {formatMoneyPunto(producto.precioCarta)}
                                        </SoftTypography>
                                      )}
                                      <SoftTypography
                                        variant="h6"
                                        color={
                                          menusDelDia?.menusDelDia
                                            ?.map((menu) => menu.producto.id)
                                            .includes(producto.id)
                                            ? "primary"
                                            : "text"
                                        }
                                        sx={{
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        ${" "}
                                        {formatMoneyPunto(
                                          menusDelDia?.menusDelDia?.find(
                                            (menu) => menu.producto.id === producto.id
                                          )?.precio || producto.precioCarta
                                        )}
                                      </SoftTypography>
                                    </SoftBox>
                                    <SoftBox
                                      sx={{
                                        display: {
                                          xs: "none",
                                          sm: "block",
                                        },
                                      }}
                                      ml={2}
                                    >
                                      <Tooltip title={"Quitar del carrito"} placement="top">
                                        <SoftButton
                                          color="primary"
                                          circular
                                          iconOnly
                                          fullWidth={{
                                            xs: true,
                                            sm: false,
                                          }}
                                          onClick={() => {
                                            const newCarrito = JSON.parse(JSON.stringify(carrito));
                                            newCarrito[index].eliminado = true;
                                            setCarrito(newCarrito);
                                          }}
                                        >
                                          <Icon>close</Icon>
                                        </SoftButton>
                                      </Tooltip>
                                    </SoftBox>
                                    <SoftBox
                                      ml={2}
                                      sx={{
                                        display: {
                                          xs: "block",
                                          sm: "none",
                                        },
                                      }}
                                    >
                                      <SoftButton
                                        color="primary"
                                        circular
                                        fullWidth
                                        onClick={() => {
                                          const newCarrito = JSON.parse(JSON.stringify(carrito));
                                          newCarrito[index].eliminado = true;
                                          setCarrito(newCarrito);
                                        }}
                                      >
                                        <Icon>close</Icon>
                                        &nbsp;Quitar
                                      </SoftButton>
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              </SoftBox>
                            </SoftBox>
                          </Slide>
                        ))}
                      </Collapse>
                    </Card>
                    <Card
                      sx={{
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        position: "sticky",
                        marginTop: -2,
                        zIndex: 1,
                      }}
                    >
                      <SoftBox bgColor="primary">
                        <SoftBox
                          display="flex"
                          justifyContent="center"
                          bgColor="primary"
                          p={2}
                          pt={4}
                        >
                          <SoftTypography variant="h6" fontWeight="bold" color="white">
                            Total: ${" "}
                            {formatMoneyPunto(
                              carrito
                                .filter((item) => !item.eliminado)
                                .reduce(
                                  (acc, item) =>
                                    acc +
                                    (menusDelDia?.menusDelDia?.find(
                                      (menu) => menu.producto.id === item.id
                                    )?.precio || item.precioCarta),
                                  0
                                )
                            )}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                    <SoftBox mt={2}>
                      <SoftButton
                        color="uproGreen"
                        circular
                        fullWidth
                        onClick={() => setOpenModalConfirmar(true)}
                      >
                        Realizar pedido
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </Slide>
              </SoftBox>
            </SoftBox>
            <ModalConfirmarPedido
              open={openModalConfirmar}
              handleClose={() => setOpenModalConfirmar(false)}
              handleCloseAll={() => {
                setOpenModalConfirmar(false);
                handleClose();
                refetch();
              }}
              carrito={carrito?.filter((item) => !item.eliminado)}
              fechaPedido={fechaPedido}
              menusDelDia={menusDelDia?.menusDelDia}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalNuevoPedido.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
};
