import { Grid, Slide } from "@mui/material";
import SoftBox from "components/SoftBox";
import PropTypes from "prop-types";
import ProfileCard from "../ProfileCard";
import Calendario from "components/Calendario";
import EventosCard from "components/Calendario/Eventos";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { gql, useLazyQuery } from "@apollo/client";
import { getUproColor } from "utils/colors";
import ModalExamen from "../ModalExamen";
import ModalTomarAsistencia from "../ModalTomarAsistencia";

const arrayDias = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];

export default function Resumen({ usuario, materia, loading, refetch, setTab }) {
  const [selectedFecha, setSelectedFecha] = useState(null);
  const [selectedExamen, setSelectedExamen] = useState(null);
  const [selectedComision, setSelectedComision] = useState(null);
  const [openExamenModal, setOpenExamenModal] = useState(false);
  const [openTomarAsistenciaModal, setOpenTomarAsistenciaModal] = useState(false);
  const [selectedMes, setSelectedMes] = useState(dayjs().month());
  const [eventos, setEventos] = useState([]);
  const [examenes, setExamenes] = useState([]);

  const [getExamenes, { refetch: refetchExamenes }] = useLazyQuery(
    gql`
      query getExamenes($filter: JSON, $fechaInicio: String, $fechaFin: String) {
        examenes(filter: $filter, fechaInicio: $fechaInicio, fechaFin: $fechaFin) {
          id
          titulo
          fecha
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const [
    getComisiones,
    { loading: loadingComisiones, data: comisiones, refetch: refetchComisiones },
  ] = useLazyQuery(
    gql`
      query getComisiones($filter: JSON) {
        paginationInfo {
          pages
          total
        }
        comisiones(filter: $filter) {
          id
          nombre
          codigo
          color
          asistenciaIniciada
          fechaInicio
          fechaFin
          horarios {
            dia
            aula {
              id
              nombre
            }
            horarioInicio
            horarioFin
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (usuario && materia && selectedMes !== null && selectedMes !== undefined) {
      getExamenes({
        variables: {
          filter: {
            idMateria: materia.id,
          },
          fechaInicio: dayjs().month(selectedMes).startOf("month").toISOString(),
          fechaFin: dayjs().month(selectedMes).endOf("month").toISOString(),
        },
      }).then((res) => {
        setExamenes(res.data.examenes);
      });
      getComisiones({
        variables: {
          filter: { idMateria: materia.id, idDocente: usuario.id, estado: "Activo" },
        },
      });
    }
  }, [usuario, materia, getExamenes, selectedMes, loading]);

  useEffect(() => {
    let eventos = [];
    if (examenes) {
      examenes.forEach((examen) => {
        eventos.push({
          date: examen.fecha,
          title: examen.titulo,
          type: "Examen",
          icon: "quizz",
          tooltip: "Ver inscriptos al examen",
          color: getUproColor("uproPink"),
          action: () => {
            setSelectedExamen(examen);
            setOpenExamenModal(true);
          },
        });
      });
    }
    if (comisiones) {
      comisiones.comisiones.forEach((comision) => {
        comision.horarios.forEach((horario) => {
          let fecha = dayjs().month(selectedMes).startOf("month");
          let endOfMonth = dayjs().month(selectedMes).endOf("month");
          const fechaInicio = dayjs(comision.fechaInicio);

          while (
            (fecha.isBefore(endOfMonth) || fecha.isSame(endOfMonth, "day")) &&
            (fecha.isBefore(dayjs(comision.fechaFin)) ||
              fecha.isSame(dayjs(comision.fechaFin), "day"))
          ) {
            if (
              (fecha.isSame(fechaInicio, "day") || fecha.isAfter(dayjs(comision.fechaInicio))) &&
              horario.dia === arrayDias[fecha.day()]
            ) {
              const fechaEvento = fecha.format("YYYY-MM-DD");
              eventos.push({
                date: fechaEvento,
                title: `${comision.nombre} - ${horario.aula.nombre}`,
                type: "Clase",
                icon: "class",
                tooltip: comision.asistenciaIniciada ? "Continuar asistencia" : "Tomar asistencia",
                disabled: dayjs(fecha).isAfter(dayjs(), "day"),
                color: comision.color,
                btnColor: comision.asistenciaIniciada ? "primary" : "uproGreen",
                btnIcon: comision.asistenciaIniciada ? "playlist_play" : "playlist_add",
                action: () => {
                  setSelectedComision({
                    comision,
                    fecha: fechaEvento,
                  });
                  setOpenTomarAsistenciaModal(true);
                },
              });
            }
            fecha = fecha.add(1, "day");
          }
        });
      });
    }
    setEventos(eventos);
  }, [examenes, comisiones]);

  return (
    <SoftBox
      gap={2}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        justifyContent: "center",
        gap: "1rem",
      }}
    >
      <Slide direction="right" in={selectedFecha !== null} mountOnEnter unmountOnExit>
        <SoftBox
          sx={{
            width: {
              xs: "100%",
              sm: "40%",
            },
            display: {
              xs: "none",
              sm: "flex",
            },
          }}
        >
          <EventosCard
            fecha={selectedFecha}
            eventos={
              eventos.filter((evento) => dayjs(evento.date).isSame(selectedFecha, "day")) || []
            }
            loading={loading}
          />
        </SoftBox>
      </Slide>
      <Slide direction="up" in={selectedFecha !== null} mountOnEnter unmountOnExit>
        <SoftBox
          sx={{
            width: {
              xs: "100%",
              sm: "30%",
            },
            display: {
              xs: "flex",
              sm: "none",
            },
          }}
        >
          <EventosCard
            fecha={selectedFecha}
            eventos={
              eventos.filter((evento) => dayjs(evento.date).isSame(selectedFecha, "day")) || []
            }
            loading={loading}
          />
        </SoftBox>
      </Slide>
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <SoftBox
          sx={{
            width: "100%",
            display: "flex",
            transition: "all 0.5s",
          }}
        >
          <Calendario
            events={eventos}
            onDateSelect={(date) => {
              setSelectedFecha(date);
            }}
            onMonthChange={(month) => {
              setSelectedMes(month);
            }}
          />
        </SoftBox>
      </Slide>
      <ModalExamen
        open={openExamenModal}
        handleClose={() => setOpenExamenModal(false)}
        refetch={refetchExamenes}
        selectedExamen={selectedExamen}
        comisiones={comisiones?.comisiones}
      />
      <ModalTomarAsistencia
        open={openTomarAsistenciaModal}
        handleClose={() => {
          setOpenTomarAsistenciaModal(false);
          setSelectedComision(null);
          refetchComisiones();
        }}
        selectedComision={selectedComision}
      />
    </SoftBox>
  );
}

Resumen.propTypes = {
  usuario: PropTypes.object,
  materia: PropTypes.object,
  loading: PropTypes.bool,
  refetch: PropTypes.func,
  setTab: PropTypes.func,
};
